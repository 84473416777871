import {useContext} from 'react'

import {SectionIntro, Button} from '@primer/react-brand'

import {PlanTypeContext} from '../../_context/PlanTypeContext'

import {PlanTypeSegmentedControl} from '../../_components/PlanTypeSegmentedControl'
import {PricingPlans} from '../../_components/PricingPlans'

import {SmallIdeSection} from '../SmallIdeSection'

import styles from './PricingSection.module.css'

type Props = {
  copilotBusinessContactSalesPath: string
  copilotBusinessSignupPath: string
  copilotEnterpriseContactSalesPath: string
  copilotEnterpriseSignupPath: string
  copilotProPlusSignupPath: string
  copilotProSignupPath: string
}

export function PricingSection(props: Props) {
  const {
    copilotBusinessContactSalesPath,
    copilotBusinessSignupPath,
    copilotEnterpriseContactSalesPath,
    copilotEnterpriseSignupPath,
    copilotProPlusSignupPath,
    copilotProSignupPath,
  } = props

  const {planType, setPlanType} = useContext(PlanTypeContext)

  return (
    <>
      <SectionIntro align="center" fullWidth className="pb-4">
        <SectionIntro.Label className={styles.labelBorderMuted}>Plans</SectionIntro.Label>

        <SectionIntro.Heading as="h2" size="2" weight="semibold">
          Take flight with GitHub Copilot
        </SectionIntro.Heading>
      </SectionIntro>

      <div className="d-flex flex-justify-center px-2 mb-7">
        <span className="visually-hidden" id="pricing-plans">
          Pricing plans
        </span>

        <PlanTypeSegmentedControl value={planType} onChange={setPlanType} />
      </div>

      <PricingPlans
        copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
        copilotBusinessSignupPath={copilotBusinessSignupPath}
        copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
        copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
        copilotProPlusSignupPath={copilotProPlusSignupPath}
        copilotProSignupPath={copilotProSignupPath}
      />

      <div className="mb-5">
        <SmallIdeSection />

        <div className="d-flex flex-justify-center mt-7">
          <Button as="a" href="/features/copilot/plans#compare" size="medium" hasArrow={false}>
            Compare all plan features
          </Button>
        </div>
      </div>
    </>
  )
}

export default PricingSection

try{ PricingSection.displayName ||= 'PricingSection' } catch {}