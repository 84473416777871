import {useContext} from 'react'

import {Grid, InlineLink, PricingOptions, Text} from '@primer/react-brand'
import {getAnalyticsEvent} from '@github-ui/swp-core/lib/utils/analytics'

import {PlanTypeContext, PlanTypes} from '../../_context/PlanTypeContext'

import styles from './PricingPlans.module.css'

type Props = {
  copilotBusinessContactSalesPath: string
  copilotBusinessSignupPath: string
  copilotEnterpriseContactSalesPath: string
  copilotEnterpriseSignupPath: string
  copilotProPlusSignupPath: string
  copilotProSignupPath: string
}

export function PricingPlans(props: Props) {
  const {
    copilotBusinessContactSalesPath,
    copilotBusinessSignupPath,
    copilotEnterpriseContactSalesPath,
    copilotEnterpriseSignupPath,
    copilotProPlusSignupPath,
    copilotProSignupPath,
  } = props
  const {planType} = useContext(PlanTypeContext)

  return (
    <Grid className="lp-Section-container--centerUntilMedium position-relative mt-0 mb-8" id="footnote-ref-1">
      <Grid.Column span={12} className="position-relative">
        {planType === PlanTypes.Individual ? (
          <PricingOptions
            id="individual-plan-tab-panel"
            aria-labelledby="individual-plan-tab"
            className={styles.pricingOptions}
          >
            <PricingOptions.Item>
              <PricingOptions.Heading>Free</PricingOptions.Heading>

              <PricingOptions.Description>A fast way to get started with GitHub Copilot.</PricingOptions.Description>

              <PricingOptions.Price className={styles.price} currencySymbol={''} currencyCode={''}>
                <Text as="span" font="hubot-sans" size="700" weight="normal">
                  $0
                </Text>

                <Text
                  as="span"
                  className={styles.currencyCode}
                  font="hubot-sans"
                  size="200"
                  variant="muted"
                  weight="normal"
                >
                  USD
                </Text>
              </PricingOptions.Price>

              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListItem>
                  50 agent mode or chat requests per month
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>2,000 completions per month</PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  Access to Claude 3.5 Sonnet, GPT-4o, and more
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>

              <PricingOptions.PrimaryAction as="a" href="/copilot" hasArrow={false}>
                Get started
              </PricingOptions.PrimaryAction>

              <PricingOptions.SecondaryAction
                as="a"
                href="vscode://github.copilot-chat"
                hasArrow={false}
                {...getAnalyticsEvent({
                  action: 'open_vscode',
                  tag: 'a',
                  context: 'copilot_free',
                  location: 'pricing_plans',
                })}
              >
                Open in VS Code
              </PricingOptions.SecondaryAction>
            </PricingOptions.Item>

            <PricingOptions.Item>
              <PricingOptions.Label>Most popular</PricingOptions.Label>

              <PricingOptions.Heading>Pro</PricingOptions.Heading>

              <PricingOptions.Description>
                Unlimited completions and chats with access to more models.
              </PricingOptions.Description>

              <PricingOptions.Price
                className={styles.price}
                currencySymbol={''}
                currencyCode={''}
                trailingText="per month or $100 per year"
              >
                <del className={styles.originalPriceDel}>
                  <Text className={styles.originalPrice} font="hubot-sans" size="700" variant="muted" weight="light">
                    $19
                  </Text>
                </del>

                <Text as="span" font="hubot-sans" size="700" weight="normal">
                  $10
                </Text>

                <Text
                  as="span"
                  className={styles.currencyCode}
                  font="hubot-sans"
                  size="200"
                  variant="muted"
                  weight="normal"
                >
                  USD
                </Text>
              </PricingOptions.Price>

              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListHeading>Everything in Free and:</PricingOptions.FeatureListHeading>

                <PricingOptions.FeatureListItem>
                  <strong>Unlimited</strong> agent mode and chats with GPT-4o
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  <strong>Unlimited</strong> code completions
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  Access to code review, Claude 3.7 Sonnet, o1, and more
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  6x more premium requests to use latest models than Free, with the option to buy more
                  <InlineLink href="#footnote-1">
                    <sup>1</sup>
                  </InlineLink>
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>

              <PricingOptions.PrimaryAction as="a" href={copilotProSignupPath} hasArrow={false}>
                Try for 30 days free
              </PricingOptions.PrimaryAction>

              <PricingOptions.Footnote className={styles.footnote}>
                Free for verified students, teachers, and maintainers of popular open source projects.{' '}
                <InlineLink href="https://github.com/education">Learn more</InlineLink>
              </PricingOptions.Footnote>
            </PricingOptions.Item>

            <PricingOptions.Item>
              <PricingOptions.Heading>Pro+</PricingOptions.Heading>

              <PricingOptions.Description>Maximum flexibility and model choice.</PricingOptions.Description>

              <PricingOptions.Price
                className={styles.price}
                currencySymbol={''}
                currencyCode={''}
                trailingText="per month or $390 per year"
              >
                <Text as="span" font="hubot-sans" size="700" weight="normal">
                  $39
                </Text>

                <Text
                  as="span"
                  className={styles.currencyCode}
                  font="hubot-sans"
                  size="200"
                  variant="muted"
                  weight="normal"
                >
                  USD
                </Text>
              </PricingOptions.Price>

              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListHeading>Everything in Pro and:</PricingOptions.FeatureListHeading>

                <PricingOptions.FeatureListItem>Access to all models, including GPT-4.5</PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  30x more premium requests to use latest models than Free, with the option to buy more
                  <InlineLink href="#footnote-1">
                    <sup>1</sup>
                  </InlineLink>
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>

              <PricingOptions.PrimaryAction as="a" href={copilotProPlusSignupPath} hasArrow={false}>
                Get started
              </PricingOptions.PrimaryAction>
            </PricingOptions.Item>
          </PricingOptions>
        ) : (
          <PricingOptions
            id="business-plan-tab-panel"
            aria-labelledby="business-plan-tab"
            className={styles.pricingOptions}
          >
            <PricingOptions.Item>
              <PricingOptions.Heading>Business</PricingOptions.Heading>

              <PricingOptions.Description>Accelerate workflows with GitHub Copilot.</PricingOptions.Description>

              <PricingOptions.Price
                className={styles.price}
                currencySymbol={''}
                currencyCode={''}
                trailingText="per user / month"
              >
                <Text as="span" font="hubot-sans" size="700" weight="normal">
                  $19
                </Text>

                <Text
                  as="span"
                  className={styles.currencyCode}
                  font="hubot-sans"
                  size="200"
                  variant="muted"
                  weight="normal"
                >
                  USD
                </Text>
              </PricingOptions.Price>

              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListItem>
                  <strong>Unlimited</strong> agent mode and chats with GPT-4o
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  <strong>Unlimited</strong> code completions
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  Access to code review, Claude 3.5/3.7 Sonnet, o1, and more
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  300 premium requests to use latest models per user, with the option to buy more
                  <InlineLink href="#footnote-1">
                    <sup>1</sup>
                  </InlineLink>
                </PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>User management and usage metrics</PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>IP indemnity and data privacy</PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>

              <PricingOptions.PrimaryAction as="a" href={copilotBusinessSignupPath} hasArrow={false}>
                Get started
              </PricingOptions.PrimaryAction>

              <PricingOptions.SecondaryAction as="a" href={copilotBusinessContactSalesPath} hasArrow={false}>
                Contact sales
              </PricingOptions.SecondaryAction>
            </PricingOptions.Item>

            <PricingOptions.Item>
              <PricingOptions.Label>Best value</PricingOptions.Label>

              <PricingOptions.Heading>Enterprise</PricingOptions.Heading>

              <PricingOptions.Description>
                Scale with AI agents and comprehensive model access.
              </PricingOptions.Description>

              <PricingOptions.Price
                className={styles.price}
                currencySymbol={''}
                currencyCode={''}
                trailingText="per user / month"
              >
                <Text as="span" font="hubot-sans" size="700" weight="normal">
                  $39
                </Text>

                <Text
                  as="span"
                  className={styles.currencyCode}
                  font="hubot-sans"
                  size="200"
                  variant="muted"
                  weight="normal"
                >
                  USD
                </Text>
              </PricingOptions.Price>

              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListHeading>Everything in Business and:</PricingOptions.FeatureListHeading>

                <PricingOptions.FeatureListItem>Access to all models, including GPT-4.5</PricingOptions.FeatureListItem>

                <PricingOptions.FeatureListItem>
                  3.33x more premium requests to use latest models than Business, with the option to buy more
                  <InlineLink href="#footnote-1">
                    <sup>1</sup>
                  </InlineLink>
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>

              <PricingOptions.PrimaryAction as="a" href={copilotEnterpriseSignupPath} hasArrow={false}>
                Get started
              </PricingOptions.PrimaryAction>

              <PricingOptions.SecondaryAction as="a" href={copilotEnterpriseContactSalesPath} hasArrow={false}>
                Contact sales
              </PricingOptions.SecondaryAction>
            </PricingOptions.Item>
          </PricingOptions>
        )}
      </Grid.Column>
    </Grid>
  )
}

try{ PricingPlans.displayName ||= 'PricingPlans' } catch {}