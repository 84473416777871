import {Hero} from '@primer/react-brand'

export default function HeroSection() {
  return (
    <Hero align="center" className="pb-4">
      <Hero.Heading size="2">
        Take flight with <span style={{whiteSpace: 'nowrap'}}>GitHub Copilot</span>
      </Hero.Heading>
    </Hero>
  )
}

try{ HeroSection.displayName ||= 'HeroSection' } catch {}