import resolveResponse from 'contentful-resolve-response'
import {useState, useEffect, useMemo} from 'react'

import {isFeatureEnabled} from '@github-ui/feature-flags'
import {useSearchParams} from '@github-ui/use-navigate'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ThemeProvider, Box} from '@primer/react-brand'
import {ContentfulSubnav} from '@github-ui/swp-core/components/contentful/ContentfulSubnav'

import {ZodSilentErrorBoundary} from '../../../../components/ZodSilentErrorBoundary/ZodSilentErrorBoundary'
import {toPayload} from '../../../../lib/types/payload'
import {toBrandPage, getBrandContentById} from '../../../../brand/lib/types/contentful'
import type {GenericSection} from '../../../../brand/lib/types/contentful/contentTypes/generic-section'
import {cohortFunnelBuilder} from '../../../../lib/analytics'

import CallToAction from '../_components/CallToAction'
import FaqSection from '../_components/FaqSection'
import {PlanTypeSegmentedControl} from '../_components/PlanTypeSegmentedControl/PlanTypeSegmentedControl'
import {PricingPlans} from '../_components/PricingPlans/PricingPlans'
import PricingSection from '../_components/PricingSection'
import SmallIdeSection from '../_components/SmallIdeSection'

import PricingBackground from '../_assets/pricing-bg.webp'
import PricingBackgroundSm from '../_assets/pricing-bg-sm.webp'

import {PlanTypeContext, PlanTypes, type PlanType} from '../_context/PlanTypeContext'

import {CompareTable} from './CompareTable'
import FootnotesSection from './FootnotesSection'
import Hero from './Hero'

export default function FeaturesCopilotPlansIndex() {
  const isCopilotProPlusEnabled = isFeatureEnabled('site_copilot_pro_plus')

  const [searchParams] = useSearchParams()
  const initialPlanType = (searchParams.get('plans') as PlanType) || PlanTypes.Individual
  const [planType, setPlanType] = useState<PlanType>(initialPlanType)

  useEffect(() => {
    const paramPlanType = searchParams.get('plans')
    if (paramPlanType === PlanTypes.Individual || paramPlanType === PlanTypes.Business) {
      setPlanType(paramPlanType)
    }
  }, [searchParams])

  const contextValue = useMemo(() => ({planType, setPlanType}), [planType])

  const {contentfulRawJsonResponse} = toPayload(useRoutePayload<unknown>())
  const contentfulResponse = resolveResponse(contentfulRawJsonResponse)
  const page = toBrandPage(contentfulResponse)

  const {template} = page.fields
  const {subnav, content} = template.fields

  const plansComparison = getBrandContentById({content, id: 'featuresCopilotPlansComparison'}) as GenericSection

  const faqSection = getBrandContentById({content, id: 'featuresCopilotFaqSection'})
  const faqComponent = faqSection?.fields.content?.find(
    item => item.sys.contentType.sys.id === 'primerComponentFaqGroup',
  )

  const {userHasOrgs} = useRoutePayload<{userHasOrgs: boolean}>()

  let {copilotBusinessContactSalesPath} = useRoutePayload<{copilotBusinessContactSalesPath: string}>()
  let {copilotBusinessSignupPath} = useRoutePayload<{copilotBusinessSignupPath: string}>()
  let {copilotEnterpriseContactSalesPath} = useRoutePayload<{copilotEnterpriseContactSalesPath: string}>()
  let {copilotEnterpriseSignupPath} = useRoutePayload<{copilotEnterpriseSignupPath: string}>()
  let {copilotProPlusSignupPath} = useRoutePayload<{copilotProPlusSignupPath: string}>()
  let {copilotProSignupPath} = useRoutePayload<{copilotProSignupPath: string}>()
  const {cft} = useRoutePayload<{cft: string}>()

  const withCft = cohortFunnelBuilder(cft)

  copilotBusinessContactSalesPath = withCft(copilotBusinessContactSalesPath)
  copilotBusinessSignupPath = withCft(copilotBusinessSignupPath, {product: 'cfb'})
  copilotEnterpriseContactSalesPath = withCft(copilotEnterpriseContactSalesPath)
  copilotEnterpriseSignupPath = withCft(copilotEnterpriseSignupPath, {product: 'ce'})
  copilotProSignupPath = withCft(copilotProSignupPath, {product: 'cfi'})
  copilotProPlusSignupPath = withCft(copilotProPlusSignupPath, {product: 'cpp'})

  if (isCopilotProPlusEnabled) {
    return (
      <ZodSilentErrorBoundary>
        <PlanTypeContext.Provider value={contextValue}>
          <ThemeProvider colorMode="dark" className="lp-Copilot position-relative">
            <Box className="lp-SubNav-spacer" />

            {subnav ? (
              <ContentfulSubnav component={subnav} className="lp-Hero-subnav lp-Hero-subnav--highContrast" />
            ) : null}

            <picture>
              <source srcSet={PricingBackgroundSm} media="(max-width: 767px)" />
              <img src={PricingBackground} className="position-absolute top-0 left-0 width-full height-auto" alt="" />
            </picture>

            <div className="position-relative">
              <Hero />

              <div className="d-flex flex-justify-center px-2 mb-7">
                <h2 className="visually-hidden" id="pricing-plans">
                  Pricing plans
                </h2>

                <PlanTypeSegmentedControl value={planType} onChange={setPlanType} />
              </div>
            </div>

            <PricingPlans
              copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
              copilotBusinessSignupPath={copilotBusinessSignupPath}
              copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
              copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
              copilotProPlusSignupPath={copilotProPlusSignupPath}
              copilotProSignupPath={copilotProSignupPath}
            />

            <SmallIdeSection />

            {plansComparison ? <CompareTable contentfulContent={plansComparison} /> : null}

            <section className="lp-Section pt-5 pt-lg-8">
              <CallToAction copilotContactSalesPath={copilotBusinessContactSalesPath} />
            </section>

            {faqComponent ? <FaqSection contentfulContent={faqComponent} /> : null}

            <FootnotesSection />
          </ThemeProvider>
        </PlanTypeContext.Provider>
      </ZodSilentErrorBoundary>
    )
  }

  return (
    <ZodSilentErrorBoundary>
      <ThemeProvider colorMode="dark" className="lp-Copilot">
        <div className="position-relative">
          <Box className="lp-SubNav-spacer" />

          {subnav ? (
            <ContentfulSubnav component={subnav} className="lp-Hero-subnav lp-Hero-subnav--highContrast" />
          ) : null}

          <picture>
            <source srcSet={PricingBackgroundSm} media="(max-width: 767px)" />
            <img src={PricingBackground} className="position-absolute top-0 left-0 width-full height-auto" alt="" />
          </picture>

          <PricingSection
            copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
            copilotBusinessSignupPath={copilotBusinessSignupPath}
            copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
            copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
            copilotProSignupPath={copilotProSignupPath}
            userHasOrgs={userHasOrgs}
            isExpanded
            showLabel={false}
            className="lp-Section--hero"
            headingLevel="h1"
          />
        </div>

        <section className="lp-Section pt-5 pt-lg-8">
          <CallToAction copilotContactSalesPath={copilotBusinessContactSalesPath} />
        </section>

        {faqComponent ? <FaqSection contentfulContent={faqComponent} /> : null}

        <FootnotesSection />
      </ThemeProvider>
    </ZodSilentErrorBoundary>
  )
}

try{ FeaturesCopilotPlansIndex.displayName ||= 'FeaturesCopilotPlansIndex' } catch {}