import {useState, useEffect, useMemo} from 'react'
import resolveResponse from 'contentful-resolve-response'

import {isFeatureEnabled} from '@github-ui/feature-flags'
import {useSearchParams} from '@github-ui/use-navigate'

import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ThemeProvider} from '@primer/react-brand'

import {cohortFunnelBuilder} from '../../../lib/analytics'
import {ZodSilentErrorBoundary} from '../../../components/ZodSilentErrorBoundary/ZodSilentErrorBoundary'
import {toPayload} from '../../../lib/types/payload'
import {toBrandPage, getBrandContentById} from '../../../brand/lib/types/contentful'

import FaqSection from './_components/FaqSection'
import FeaturesSection from './_components/FeaturesSection'
import FootnotesSection from './_components/FootnotesSection'
import HeroSection from './_components/HeroSection'
import IntegrationsSection from './_components/IntegrationsSection'
import PricingSection from './_components/PricingSection'
import ResourcesSection from './_components/ResourcesSection'

import {PricingSection as NewPricingSection} from './_sections/PricingSection/PricingSection'

import {PlanTypeContext, PlanTypes, type PlanType} from './_context/PlanTypeContext'

export default function FeaturesCopilotIndex() {
  const isCopilotProPlusEnabled = isFeatureEnabled('site_copilot_pro_plus')

  const [searchParams] = useSearchParams()
  const initialPlanType = (searchParams.get('plans') as PlanType) || PlanTypes.Individual
  const [planType, setPlanType] = useState<PlanType>(initialPlanType)

  useEffect(() => {
    const paramPlanType = searchParams.get('plans')
    if (paramPlanType === PlanTypes.Individual || paramPlanType === PlanTypes.Business) {
      setPlanType(paramPlanType)
    }
  }, [searchParams])

  const contextValue = useMemo(() => ({planType, setPlanType}), [planType])

  const {contentfulRawJsonResponse} = toPayload(useRoutePayload<unknown>())
  const contentfulResponse = resolveResponse(contentfulRawJsonResponse)
  const page = toBrandPage(contentfulResponse)

  const {template} = page.fields
  const {subnav, content} = template.fields
  const faqSection = getBrandContentById({content, id: 'featuresCopilotFaqSection'})
  const faqComponent = faqSection?.fields.content?.find(
    item => item.sys.contentType.sys.id === 'primerComponentFaqGroup',
  )

  const {userHasOrgs} = useRoutePayload<{userHasOrgs: boolean}>()

  let {copilotBusinessContactSalesPath} = useRoutePayload<{copilotBusinessContactSalesPath: string}>()
  let {copilotBusinessSignupPath} = useRoutePayload<{copilotBusinessSignupPath: string}>()
  let {copilotEnterpriseContactSalesPath} = useRoutePayload<{copilotEnterpriseContactSalesPath: string}>()
  let {copilotEnterpriseSignupPath} = useRoutePayload<{copilotEnterpriseSignupPath: string}>()
  let {copilotProPlusSignupPath} = useRoutePayload<{copilotProPlusSignupPath: string}>()
  let {copilotProSignupPath} = useRoutePayload<{copilotProSignupPath: string}>()
  const {cft} = useRoutePayload<{cft: string}>()

  const withCft = cohortFunnelBuilder(cft)
  copilotBusinessContactSalesPath = withCft(copilotBusinessContactSalesPath)
  copilotBusinessSignupPath = withCft(copilotBusinessSignupPath, {product: 'cfb'})
  copilotEnterpriseContactSalesPath = withCft(copilotEnterpriseContactSalesPath)
  copilotEnterpriseSignupPath = withCft(copilotEnterpriseSignupPath, {product: 'ce'})
  copilotProSignupPath = withCft(copilotProSignupPath, {product: 'cfi'})
  copilotProPlusSignupPath = withCft(copilotProPlusSignupPath, {product: 'cpp'})

  const copilotPlansPath = withCft('/features/copilot/plans')

  if (isCopilotProPlusEnabled) {
    return (
      <ZodSilentErrorBoundary>
        <PlanTypeContext.Provider value={contextValue}>
          <ThemeProvider colorMode="dark" className="lp-Copilot">
            <HeroSection copilotPlansPath={copilotPlansPath} subnav={subnav} />

            <FeaturesSection />

            <NewPricingSection
              copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
              copilotBusinessSignupPath={copilotBusinessSignupPath}
              copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
              copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
              copilotProPlusSignupPath={copilotProPlusSignupPath}
              copilotProSignupPath={copilotProSignupPath}
            />

            <IntegrationsSection />

            <ResourcesSection />

            {faqComponent ? <FaqSection contentfulContent={faqComponent} /> : null}

            <FootnotesSection />
          </ThemeProvider>
        </PlanTypeContext.Provider>
      </ZodSilentErrorBoundary>
    )
  }

  return (
    <ZodSilentErrorBoundary>
      <ThemeProvider colorMode="dark" className="lp-Copilot">
        <HeroSection copilotPlansPath={copilotPlansPath} subnav={subnav} />

        <FeaturesSection />

        <PricingSection
          copilotProSignupPath={copilotProSignupPath}
          copilotBusinessSignupPath={copilotBusinessSignupPath}
          copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
          copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
          copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
          userHasOrgs={userHasOrgs}
          isExpanded
        />

        <ResourcesSection />

        {faqComponent ? <FaqSection contentfulContent={faqComponent} /> : null}

        <FootnotesSection />
      </ThemeProvider>
    </ZodSilentErrorBoundary>
  )
}

try{ FeaturesCopilotIndex.displayName ||= 'FeaturesCopilotIndex' } catch {}